import React, { useState } from 'react';
import { Col, Row, Button, Modal, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';
import ViewProReceipt from './components/Modals/ViewProReceipt';
import { SPA_FIREBASE_FCM } from '../../context/globals';
import db from '../../context/firestore';
import useAuth from '../../hooks/useAuth';
import ProductTip from '../Products/components/ProductTip';
import Pay from '../Payment/Pay';
import { deviceId } from '../../context/firestore';
import { useNavigate } from 'react-router-dom';
import {
  IoInformationCircle,
  IoCloseCircleSharp,
  IoLogIn,
} from 'react-icons/io5';
import { doc, serverTimestamp, getDoc, deleteDoc } from 'firebase/firestore';
import ViewProInfo from '../Products/components/Modals/VIewProInfo';
//const ENV = process.env.REACT_APP_ENV;
const REACT_APP_PRODUCT_SPA_URL = process.env.REACT_APP_PRODUCT_SPA_URL;
const SPA_SERVER_URL = process.env.REACT_APP_SPA_SERVER_URL;

function Product({ product }) {
  //
  const { auth } = useAuth();
  const navigate = useNavigate();
  const token = auth.access_token;
  const cashier_id = auth.ecode;
  const MySwal = withReactContent(Swal);
  // Payment Setup
  const [payment, setPayment] = useState('span');
  const [showProduct, setShowProduct] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseProduct = () => {
    setShowProduct(false);
    setPayment('span');
  };
  // Product Rceipt Modal States
  const [showProReceipt, setShowProReceipt] = useState(false);
  const handleCloseProReceipt = () => setShowProReceipt(false);
  const handleShowProReceipt = () => setShowProReceipt(true);
  const handleShowProduct = () => setShowProduct(true);

  //
  const rejectOrder = () => {
    MySwal.fire({
      input: 'textarea',
      inputLabel: 'Do you want to cancel Order No(' + product.id + ')?',
      inputPlaceholder: 'Type Reason to Cancel...',
      inputAttributes: {
        'aria-label': 'Type Reason to Cancel',
      },
      showCancelButton: true,
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          const reason = result.value;
          getOrderByID(product.id).then(async (response) => {
            let orderFinal = response;
            orderFinal.status = 'r';
            orderFinal.id = product.id;
            orderFinal.datetime_rejected = new Date()
              .toISOString()
              .replace('Z', '')
              .replace('T', ' ');
            // Voucher Date
            if (orderFinal.datetime_voucher) {
              orderFinal.datetime_voucher = new Date(
                orderFinal.datetime_voucher.seconds * 1000
              )
                .toISOString()
                .replace('Z', '')
                .replace('T', ' ');
            } else {
              orderFinal.datetime_voucher = null;
            }
            orderFinal.cancel_reason = reason;
            orderFinal.cashier_id = cashier_id;
            if (orderFinal.datetime_submitted) {
              orderFinal.datetime_submitted = new Date(
                orderFinal.datetime_submitted.seconds * 1000
              )
                .toISOString()
                .replace('Z', '')
                .replace('T', ' ');
            } else {
              orderFinal.datetime_submitted = null;
            }
            orderFinal = JSON.stringify(response);
            console.log(orderFinal);
            if (token) {
              const URL = SPA_SERVER_URL + 'api/spa/spaproductcloseorder';
              const options = {
                method: 'POST',
                headers: {
                  Authorization: 'Bearer ' + token,
                  deviceid: deviceId,
                  'Content-Type':
                    'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: 'request=' + orderFinal,
              };

              const response = await fetch(URL, options);
              const responseJson = await response.json();
              console.log(responseJson);
              if (responseJson.response === true) {
                toast.success(responseJson.message);
                await deleteDoc(
                  doc(db, REACT_APP_PRODUCT_SPA_URL, product.id.toString())
                );
                // Firebase Notifications
                SPA_FIREBASE_FCM(product.id, product.ecode, 'reject');
              } else if (response.status === 401) {
                navigate('/login');
                toast.error(responseJson.message);
              } else {
                toast.error(responseJson.message);
              }
            } else {
              toast.error('You Session is Expired!');
              navigate('/login');
            }
          });
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const getOrderByID = async (id) => {
    const docRef = doc(db, REACT_APP_PRODUCT_SPA_URL, id.toString());
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log('Document data:', docSnap.data());
      return { ...docSnap.data(), id: docSnap.id };
      // return docSnap.data();
    } else {
      // doc.data() will be undefined in this case
      console.log('No such document!');
    }
  };

  // Close Order Directly
  const closeOrderDirectly = () => {
    console.log('closing Directly');
    let paymentmethod = null;
    let payment_detail = [];
    if ((product.payment_type = 'Settle To Villa')) {
      paymentmethod = 'Settle To Villa';
    }
    if ((product.payment_type = 'Settle By Ledger')) {
      paymentmethod = 'Settle By Ledger';
    }
    if ((product.payment_type = 'Settle by Voucher')) {
      paymentmethod = 'Settle by Voucher';
    }
    payment_detail.push({
      id: 0,
      paymentmethod: paymentmethod,
      amount: product.total,
    });
    // Check Empty Payment Methods
    if (paymentmethod !== null) {
      callbackFunction(payment_detail, paymentmethod);
    }
  };
  const callbackFunction = async (payment_detail, main_payment_method) => {
    getOrderByID(product.id).then(async (response) => {
      let orderFinal = response;
      orderFinal.id = product.id;
      orderFinal.payment_detail = payment_detail;
      orderFinal.payment_type = main_payment_method;
      orderFinal.cashier_id = cashier_id;
      orderFinal.status = 'c';
      // Datetime Submitted
      if (orderFinal.datetime_submitted) {
        orderFinal.datetime_submitted = new Date(
          orderFinal.datetime_submitted.seconds * 1000
        )
          .toISOString()
          .replace('Z', '')
          .replace('T', ' ');
      } else {
        orderFinal.datetime_submitted = null;
      }
      // Voucher Date
      if (orderFinal.datetime_voucher) {
        orderFinal.datetime_voucher = new Date(
          orderFinal.datetime_voucher.seconds * 1000
        )
          .toISOString()
          .replace('Z', '')
          .replace('T', ' ');
      } else {
        orderFinal.datetime_voucher = null;
      }
      // Datetime Closed
      orderFinal.datetime_closed = new Date()
        .toISOString()
        .replace('Z', '')
        .replace('T', ' ');

      orderFinal = JSON.stringify(response);
      console.log(orderFinal);
      if (token) {
        const URL = SPA_SERVER_URL + 'api/spa/spaproductcloseorder';
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            deviceid: deviceId,
            Authorization: 'Bearer ' + token,
          },
          body: 'request=' + orderFinal,
        };

        const response = await fetch(URL, options);

        const responseJson = await response.json();
        if (responseJson.response === true) {
          toast.success(responseJson.message);
          await deleteDoc(
            doc(db, REACT_APP_PRODUCT_SPA_URL, product.id.toString())
          );
          handleCloseProduct();
        } else if (response.status === 401) {
          navigate('/login');
          toast.error(responseJson.message);
        } else {
          toast.error(responseJson.message);
        }
      } else {
        toast.error('Your Session is Expired');
        navigate('/login');
      }
    });
  };

  const handleChange = (e) => {
    const pay = e.target.value;
    setPayment(pay);
  };

  // const updateOrderStatus = async (id) => {
  //   const docRef = doc(db, REACT_APP_PRODUCT_SPA_URL + id.toString());
  //   await updateDoc(docRef, {
  //     status: 'a',
  //     datetime_approved: serverTimestamp(),
  //   });
  //   toast.success('Order Confirmed');
  //   // handleCloseStaff();
  //   // Firebase Notifications
  //   if (token) {
  //     SPA_FIREBASE_FCM(order.id, order.ecode, 'reject');
  //   } else {
  //     toast.error('Your Session is Expired NO Token!');
  //   }
  // };
  // const updateOrder = async (id) => {
  //   const docRef = doc(db, REACT_APP_PRODUCT_SPA_URL + id.toString());
  //   const docSnap = await getDoc(docRef);
  //   const dataCom = docSnap.data();

  //   if (
  //     product.type?.toLowerCase() === 'villa' ||
  //     product.type?.toLowerCase() === 'outdoor' ||
  //     product.room_type?.toLowerCase() === 'salon'
  //   ) {
  //     // Check Only Staff
  //     if (dataCom.assigned_staff_name) {
  //       updateOrderStatus(id);
  //     } else {
  //       toast.error('Please Select Staff First');
  //     }
  //   } else if (product.room_type?.toLowerCase() === 'single room') {
  //     // if single room
  //     if (dataCom.assigned_staff_name && dataCom.assigned_room) {
  //       updateOrderStatus(id);
  //     } else {
  //       toast.error('Please Select Staff & Room.');
  //     }
  //   } else if (product.room_type?.toLowerCase() === 'couple room') {
  //     if (
  //       dataCom.assigned_staff_name &&
  //       dataCom.assigned_staff_couple_name &&
  //       dataCom.assigned_room &&
  //       dataCom.assigned_couple_room
  //     ) {
  //       updateOrderStatus(id);
  //     } else {
  //       toast.error('Please Select Staff & Rooms.');
  //     }
  //   } else {
  //     // No validation
  //     updateOrderStatus(id);
  //   }
  // };
  const convertDate = (date) => {
    if (date != null) {
      return new Date(date.seconds * 1000).toLocaleString([], {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    }
  };
  return (
    <Col md={6} className='product-container'>
      <Col md={12} className='product px-3'>
        <Row>
          <Col sm={6} className='order-id'>
            OrderID: {product.id}
          </Col>
          <Col sm={6} className='order-total'>
            Total: {product.total.toFixed(2)} SAR
          </Col>
        </Row>
        <Row className='pt-2'>
          <Col sm={2}>
            <b>Name:</b>
          </Col>
          <Col sm={10} className='name'>
            {product.name ? product.name : 'N/A'}
          </Col>
        </Row>
        <Row className='pt-2'>
          <Col sm={2}>
            <b>Contact:</b>
          </Col>
          <Col sm={4}>{product.contact ? product.contact : 'N/A'}</Col>
          <Col sm={2}>
            <b>eCode:</b>
          </Col>
          <Col sm={4}>{product.ecode ? product.ecode : 'N/A'}</Col>
        </Row>
        <Row className='pt-2'>
          <Col sm={2}>
            <b>Payment:</b>
          </Col>
          <Col sm={4} className='text-capitalize'>
            {product.payment_type}
          </Col>
          <Col sm={2}>
            <b>Ordered:</b>
          </Col>
          <Col sm={4} className='text-capitalize date-class'>
            {convertDate(product.datetime_submitted)}
          </Col>
        </Row>
        <Row className='pt-2'>
          <Col>
            {/* <b>Type:</b>
          </Col>
          <Col sm={4} className="text-capitalize">
            {product.type ? product.type : 'N/A'} */}
          </Col>
          <Col sm={2}>
            <b>Products:</b>
          </Col>
          <Col sm={4} className='text-capitalize'>
            {product?.product_count}
          </Col>
        </Row>

        <Row>
          <Col md={{ span: 12, offset: 0 }} className='mt-4 inner_btn'>
            <Button
              className='order-btn m-1'
              variant='light'
              onClick={
                product.payment_type.toLowerCase() === 'settle by voucher' ||
                product.payment_type.toLowerCase() === 'settle to villa' ||
                product.payment_type.toLowerCase() === 'settle by ledger'
                  ? closeOrderDirectly
                  : handleShowProduct
              }
            >
              <IoLogIn className='icon-class' />
              Close Order
            </Button>
            <Button
              className='order-btn m-1'
              variant='light'
              onClick={rejectOrder}
            >
              <IoCloseCircleSharp className='icon-class' />
              Cancel Order
            </Button>
            <Button
              className='order-btn m-1'
              variant='light'
              onClick={handleShow}
            >
              <IoInformationCircle className='icon-class' />
              View Info
            </Button>
            <Button
              className='order-btn m-1'
              variant='light'
              onClick={handleShowProReceipt}
            >
              <IoInformationCircle className='icon-class' />
              View Receipt
            </Button>
          </Col>
        </Row>
      </Col>
      {/* Modal Order Close*/}
      <Modal
        show={showProduct}
        size='lg'
        onHide={handleCloseProduct}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton className='modal-header-dark'>
          <Modal.Title className='modal-header-title'>
            Product Order Info
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='mx-3'>
          <Row className='p-2'>
            <Col sm={3}>
              <b>Order ID:</b>
            </Col>
            <Col sm={3}>{product.id}</Col>
            <Col sm={3}>
              <b>eCode:</b>
            </Col>
            <Col sm={3}>{product.ecode}</Col>
          </Row>

          <Row className='p-2'>
            <Col sm={3}>
              <b>Sub Total:</b>
            </Col>
            <Col sm={3} className='text-capitalize'>
              {product.subtotal.toFixed(2)} SAR
            </Col>
            <Col sm={3}>
              <b>Total:</b>
            </Col>
            <Col className='text-capitalize' sm={3}>
              <span className='fw-bold fs-5'>
                {product.total.toFixed(2)} SAR
              </span>
            </Col>
          </Row>
          <Row className='p-2'>
            <Col sm={3}>
              <b>Payment By:</b>
            </Col>
            <Col sm={3} className='text-capitalize'>
              {product.paymenttype}
            </Col>
            <Col sm={3}>
              <b>Total Products:</b>
            </Col>
            <Col className='text-capitalize' sm={3}>
              {product.product_count}
            </Col>
          </Row>
          <Row className='p-2'>
            <Col sm={3}>
              <b>Voucher:</b>
            </Col>
            <Col sm={3}>{product.isvoucher ? 'Yes' : 'N/A'}</Col>
            <Col sm={3}>
              <b>Tip:</b>
            </Col>
            <Col sm={3}>
              {product.tip || product.tip === 0
                ? product.tip.toFixed(2)
                : 'N/A'}{' '}
              SAR
            </Col>
          </Row>
          <Row className='p-2'>
            <Col sm={3}>
              <b>Voucher Amount:</b>
            </Col>
            <Col sm={3}>
              {product.voucher_amount ? product.voucher_amount : 'N/A'}
            </Col>
            <Col sm={3}>
              <b>VAT:</b>
            </Col>
            <Col sm={3}>{product.vat ? product.vat.toFixed(2) : 'N/A'} SAR</Col>
          </Row>
          <Row>
            <ProductTip
              id={product.id}
              ordertip={product.tip !== undefined ? product.tip : 0}
            />
          </Row>
          <Row className='mt-5'>
            <Col md={{ span: 10, offset: 2 }}>
              <Form>
                <Form.Check
                  defaultChecked
                  inline
                  label='Span Payment'
                  name='payment'
                  type='radio'
                  value='span'
                  onChange={handleChange}
                />
                <Form.Check
                  inline
                  label='Cash Payment'
                  name='payment'
                  type='radio'
                  value='cash'
                  onChange={handleChange}
                />
                <Form.Check
                  inline
                  label='Split Bill'
                  name='payment'
                  type='radio'
                  value='split'
                  onChange={handleChange}
                />
              </Form>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Pay
              payment={payment}
              total={product.total}
              getPaymentDetails={callbackFunction}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='light' onClick={handleCloseProduct}>
            Close
          </Button>
          {/* <Button className="order-btn m-1" variant="light">
            <IoCheckmarkCircle className="icon-class" />
            Update Order
          </Button> */}
        </Modal.Footer>
      </Modal>
      {/* Modal */}
      {/* Order Info */}
      <ViewProInfo product={product} handleClose={handleClose} show={show} />
      {/* Modal Product Receipt View */}
      <ViewProReceipt
        product={product}
        showProReceipt={showProReceipt}
        handleCloseProReceipt={handleCloseProReceipt}
      />
    </Col>
  );
}

export default Product;
